import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { HiHome, HiClipboardList, HiUserGroup, HiOutlineLogout } from "react-icons/hi";
import { AuthContext } from '../../shared/context/auth-context';


const Sidebar = () => {
  const auth = useContext(AuthContext);
  return (
    <>
    <div className="side-nav side-nav-transparent side-nav-expand bg-blue-900 hidden md:block" style={{width: "290px"}}>
      <div className="side-nav-header">
        <div className="logo px-6 py-4">
          <h1 className='text-2xl text-white'>xMeetings</h1>
        </div>
      </div>
    {/*  */}
        <div className="side-nav-content">
            <nav className="menu menu-transparent px-4 pb-4">
              <div className="menu-group">
                  <ul>
                      <li className='menu-item  text-white  menu-item-hoverable mb-2'>
                        <Link to="/dashboard" className='flex items-center h-full w-full'>
                        <span className='text-2xl ltr:mr-2 rtl:ml-2'><HiHome /></span>
                        <span>Dashboard</span>
                        </Link>
                      </li>
                      <li className='menu-item text-white menu-item-hoverable mb-2'>
                        <Link to="/meetings" className='flex items-center h-full w-full'>
                        <span className='text-2xl ltr:mr-2 rtl:ml-2'><HiUserGroup /></span>
                        <span>Meetings</span>
                        </Link>
                      </li>
                      <li className='menu-item text-white menu-item-hoverable mb-2'>
                        <Link to="/reports" className='flex items-center h-full w-full'>
                        <span className='text-2xl ltr:mr-2 rtl:ml-2'><HiClipboardList /></span>
                        <span>Reports</span>
                        </Link>
                      </li>
                      <li className='menu-item text-white menu-item-hoverable mb-2'>
                        <Link to="/add-bluk-meetings" className='flex items-center h-full w-full'>
                        <span className='text-2xl ltr:mr-2 rtl:ml-2'><HiUserGroup /></span>
                        <span>Import Meetings</span>
                        </Link>
                      </li>
                      {auth.isLoggedIn && (
                        <li className='menu-item text-white menu-item-hoverable mb-2'>
                        <Link onClick={auth.logout} to='javascript:void();' className='flex items-center h-full w-full'>
                        <span className='text-2xl ltr:mr-2 rtl:ml-2'><HiOutlineLogout /></span>
                        <span>Logout</span>
                        </Link>
                      </li> 
                      )}
                      
                  </ul>
              </div>
            </nav>
        </div>
    {/*  */}

</div>
{/* END  */}
    </>
  )
}

export default Sidebar