import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Input,
  Button,
  FormItem,
  FormContainer,
} from 'components/ui';
import { Field, Form, Formik } from 'formik';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";
import { useNavigate } from 'react-router-dom';

function BulkMeetingForm(props) {
  const auth = useContext(AuthContext);
  const formRef = useRef();
  const fileInputRef = useRef(null); 
  const intervalId = useRef(null);

  const navigate = useNavigate();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userName, userLogin, userEmail } = useAuth();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [progress, setProgress] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to check import progress
  const checkImportProgress = async () => {
    if (!batchId) return; // Only proceed if batchId is available

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_ASSET_URL}/api/import-progress/${batchId}`,
        'GET',
        null,
        {
          'Authorization': 'Bearer ' + auth.token,
        }
      );

      setProgress(response);
      setIsSubmitting(true);
      console.log(response);
      if(response.some(item=>item.row_number=='end')){
        console.log('dsalfjdlkajl')
        clearInterval(intervalId.current);
      }
      
    } catch (err) {
      console.error('Error fetching progress:', err);
    }
  };

  // Poll the server every 5 seconds to get progress updates
  useEffect(() => {
    if (!batchId) return; // Only start polling if batchId is set

    intervalId.current = setInterval(checkImportProgress, 3000);
    
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current); // Cleanup on component unmount
      }
    }; // Cleanup on component unmount
  }, [batchId, sendRequest]);

  return (
    <div style={{ padding: '20px 30px' }}>
      <Formik
        initialValues={{ file: null }}
        validate={values => {
          const errors = {};
          if (!values.file) {
            errors.file = 'File is required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const formData = new FormData();
          formData.append('file', values.file);
          values.file = null;
          console.log('values.file', values.file);
          try {
            const response = await sendRequest(
              `${process.env.REACT_APP_BACKEND_ASSET_URL}/api/add-bulk-meetings`, // Replace with your actual API URL
              'POST',
              formData,
              {
                'Authorization': 'Bearer ' + auth.token,
              }
            );
            setBatchId(response.batchId);
            console.log('uploading file',fileInputRef.current.value);
            fileInputRef.current.value =null;
            setProgress([]);
          } catch (err) {
            console.error(err);
            alert('Failed to import meetings');
          }

          setSubmitting(false);
          resetForm();
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <Form id='meeting-form' onSubmit={handleSubmit}>
            <FormContainer>
              <FormItem
                label="Upload File"
                asterisk
                invalid={errors.file && touched.file}
                errorMessage={errors.file}
              >
                <Input
                  type="file"
                  name="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  ref={fileInputRef}
                />
              </FormItem>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </FormContainer>
          </Form>
        )}
      </Formik>

      {/* Display Progress and Errors */}
      {batchId && 
      (<h6 style={{color: 'green'}}>File Uploaded and data queued successfully! Wait...</h6>)
      }
      {progress.length > 0 && (
        <div>
          <h6 style={{marginTop: '30px'}}>Import Progress</h6>
          <ul>
            {progress.map((item, index) => (
              <>
              <li key={index}>
                {item.row_number!='end' &&
                (<p style={{color: item.status=='error'?'red':'green'}}>Row {item.row_number}: {item.status} - {item.message}</p>)
                }
              </li>
              
              {item.row_number=='end' &&
                (<h4 style={{color: item.status=='error'?'red':'green'}}>{item.status} - {item.message}</h4>)}
                </>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default BulkMeetingForm;
