import { Card, Spinner, Alert} from "components/ui"
import React, { useState, useEffect, useContext } from 'react'
import dateFormat from 'dateformat';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const Dashboard = () => {
  const auth = useContext(AuthContext);
  const [loadedMeetings, setLoadedMeetings] = useState();
  const [isEmptyMeetings, setIsEmptyMeetings] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [totalHoursMinutes, setTotalHoursMinutes] = useState('0h 0m');

  const fetchMeetings = async (page) => {
    let ajaxUrl=`${process.env.REACT_APP_BACKEND_URL}/meetings?page=${page}`;
        
    
    try {
      const responseData = await sendRequest(
        ajaxUrl,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token
        }
      );
      setLoadedMeetings(responseData.data.response);
      if(responseData.data.response.data.length > 0)
      {
        setIsEmptyMeetings(false);
        setTotalHoursMinutes(responseData.data.total_hours)
      }
      else
      {
        setIsEmptyMeetings(true);
      }
    } catch (err) {}
  };

  useEffect(() => { 
    fetchMeetings(1);    
    
  }, [sendRequest, auth.userId]);

  return (
    <>
    
    <div className="page-container relative h-full flex flex-auto flex-col px-4 sm:px-6  py-4 sm:py-6 md:px-8">
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4">
    {isLoading && (
            <div className="center flex items-center">                
                <Spinner size="3.25rem" />
            </div>
          )} 

          {error && (
            <div>
              <Alert type="danger" closable  >
                  {error}
              </Alert>
          </div>
          )}           
          {isEmptyMeetings && (
            <div>
                <Alert type="danger" closable  >
                  No meeting found
                </Alert>
            </div>
          )} 
    
    {loadedMeetings && (  
      <Card> 
        <h5>{loadedMeetings.total} Meetings</h5>
      </Card>
      )}   
    
    <Card>{totalHoursMinutes}</Card>
            </div>
            {/* END #1 */}
    </div>{/* END .page-container */}


    </>
  )
}



export default Dashboard